import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const CustomerService = {

  /**
   * http://localhost/api/V1/customer
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getCustomers (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer/11/origins/monthly/destinations
   *
   * @param idCustomer
   * @param period
   * @returns {Promise<any>}
   */
  getCustomerContacts (customer, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${customer.id}/contact?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getUserCustomerModerator (customer) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/employeeaccess/${customer}/moderator`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          reject(err)
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getCustomerTierLocal (customer) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/customer/${customer.id}/tierlocal`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          reject(err)
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTipoCliente (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoas/tipo-cliente?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default CustomerService
